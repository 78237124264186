import React, { Component } from "react"
import Button from "@bit/azheng.joshua-tree.button"
import PropTypes from "prop-types"
import { getMonthYearFromStrings } from "../../utils/utils"

import FacewallImage from "../../components/carousel/FacewallImage"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"
import { Link, useStaticQuery, graphql } from "gatsby"
import ImageMeta from "../../components/ImageMeta"

var classNames = require("classnames")

class FixedFacewallHomepage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    // Only allow rows of four, reviews should be sorted by most recent to least recent
    let reviewCount = this.props.reviews.length
    let nearestFourth = Math.floor((reviewCount / 4) * 4)
    let reviews = this.props.reviews.slice(0, nearestFourth)
    const images = reviews.map((review, i) => {
      return (
        <FacewallImage
          className={i > 3 ? "is-hidden-mobile" : ""}
          key={review.title}
          imageUrl={review.thumbnailPublicId}
          reviewerName={review.reviewerName}
          procedure={review.mainProcedureDone}
          procedureEs={review.mainProcedureDoneEs}
          url={review.title}
          monthYear={review.monthYear}
          middleColumnsis={this.props.middleColumnsis}
          language={this.props.language}
        />
      )
    })

    return (
      <section
        data-aos="fade-up"
        data-aos-duration="1200"
        className={classNames(
          "home-section fixed-facewall",
          this.props.className
        )}
        style={{
          paddingTop: this.props.paddingTop,
          paddingBottom: this.props.paddingBottom
        }}
      >
        <div className="columns">
          <div className="column is-2"></div>
          <div className="column">
            <h3>{this.props.heading}</h3>
            {this.props.blurb}
          </div>
          <div className="column is-2"></div>
        </div>
        <div className="columns is-multiline procedure-patient-row">
          {images}
        </div>
        <div className="columns has-text-centered">
          <div className="column">
            {this.props.language === "es" ? (
              <Button
                contained
                buttonText="Más Pacientes"
                href="/es/historias-de-pacientes/"
              />
            ) : (
              <Button
                contained
                buttonText="More Patients"
                href="/video-reviews/"
              />
            )}
          </div>
        </div>
      </section>
    )
  }
}

FixedFacewallHomepage.propTypes = {
  reviews: PropTypes.array.isRequired,
  className: PropTypes.string,
  middleColumnsis: PropTypes.number,
  sideColumnIs: PropTypes.number,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  heading: PropTypes.string,
  blurb: PropTypes.element
}

// function FacewallImage(props) {
//   return (
//     <>
//       <div className="column is-6 fixed-facewall-item has-text-centered">
//         <div className={props.className}>
//           <Link to={`/${props.url.toLowerCase()}`}>
//             <ImageMeta
//               cloudName="nuvolum"
//               publicId={props.imageUrl}
//               data-src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_auto:best/v1/${props.imageUrl}`}
//               width="auto"
//
//               responsive
//               responsiveUseBreakpoints="true"
//               className="lazyload"
//             >
//             </ImageMeta>
//           </Link>
//           <Link className="image-caption-link" to={`/${props.url.toLowerCase()}`}>
//             <div className="image-caption-wrapper">
//               <h4 className="" style={{margin: 0, marginBottom: "0"}}>Meet {props.reviewerName}</h4>
//               <p className="" style={{margin: 0, marginBottom: "1.563rem", fontWeight: "normal"}}>
//                 {getMonthYearFromStrings(
//                   props.monthYear.year,
//                   props.monthYear.month
//                 )}
//               </p>
//             </div>
//           </Link>
//         </div>
//       </div>
//     </>
//   )
// }

// FacewallImage.propTypes = {
//   className: PropTypes.string,
//   url: PropTypes.string,
//   imageUrl: PropTypes.string.isRequired,
//   monthYear: PropTypes.object
// }

export default FixedFacewallHomepage
